export const environment = {
    production: true,
    API: 'https://www.omdbapi.com?apikey=c901dbca',
    firebaseConfig: {
      apiKey: "AIzaSyANe_cnZ3NoIZc54K4isYVrlPXa9V0ZQXQ",
      authDomain: "filmesdbase.firebaseapp.com",
      databaseURL: "https://filmesdbase.firebaseio.com",
      projectId: "filmesdbase",
      storageBucket: "filmesdbase.firebasestorage.app",
      messagingSenderId: "246899821251",
      appId: "1:246899821251:web:67d4c23b6d3cc9a9a47076",
      measurementId: "G-DH04YGTEV8"
    }
  } 