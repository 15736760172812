import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { SharedService } from "../../shared/services/shared.service";
import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: "home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
    public filmes: [];
    public options: AnimationOptions;
    public styles;

    constructor(private router: Router, private sharedService: SharedService) {
        this.options = {
            path: "/assets/animations/stars.json",
        };
    }

    ngOnInit() {
        this.getFilmes();
    }

    getFilmes(): void {
        this.sharedService.buscarFilme(this.getRandomMovieWord()).then(res => {
            this.filmes = res.Search;
        });
    }

    filmeDetalhes(id: string): void {
        this.router.navigate(["/filme-detalhes"], { queryParams: { id: id } });
    }

    scroll(el: HTMLElement): void {
        el.scrollIntoView();
    }


    // Lista de palavras comuns em títulos de filmes
private movieWords = [
    "love", "adventure", "dark", "light", "hope", "eternal", "warrior", "king", "queen", "shadow",
    "legend", "destiny", "hero", "villain", "star", "night", "day", "dream", "escape", "lost",
    "found", "time", "space", "journey", "battle", "heart", "soul", "power", "fear", "fire",
    "water", "earth", "wind", "storm", "secret", "mystery", "truth", "revenge", "return", "awakening",
    "rise", "fall", "death", "life", "glory", "blood", "promise", "bond", "revenant", "phantom",
    "savior", "hunter", "killer", "chase", "revenge", "trap", "paradise", "hell", "heaven", "curse",
    "treasure", "island", "castle", "forest", "city", "world", "universe", "galaxy", "empire", "throne",
    "freedom", "prison", "soldier", "captain", "queen", "prince", "princess", "dragon", "beast", "monster",
    "clash", "saga", "odyssey", "legacy", "vision", "future", "past", "present", "horizon", "infinity"
];

// Função para retornar uma palavra aleatória da lista
getRandomMovieWord() {
    const index = Math.floor(Math.random() * this.movieWords.length);
    return this.movieWords[index];
}

// Gerar 100 palavras aleatórias únicas
generateUniqueMovieWords(count) {
    const uniqueWords = new Set();
    while (uniqueWords.size < count) {
        uniqueWords.add(this.getRandomMovieWord());
    }
    return Array.from(uniqueWords);
}

// Chamada da função e exibição do resultado
}
