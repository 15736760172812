import { Component, OnInit } from "@angular/core";

@Component({
    selector: "spinner-load",
    templateUrl: "./spinner-load.component.html",
    styleUrls: ["./spinner-load.component.scss"]
})
export class SpinnerLoadComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
